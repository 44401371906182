import React from 'react'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import * as PropTypes from 'prop-types'
import Colors from '~shared/assets/styles/colors'

const styles = () => ({
  tooltip: {
    position: 'relative',
    display: 'inline-block',

    '&:hover $tooltipText': {
      visibility: 'visible',
      opacity: 1,
    },
  },
  tooltipText: {
    visibility: 'hidden',
    backgroundColor: Colors.white,
    color: Colors.Black,
    textAlign: 'center',
    borderRadius: '16px',
    padding: '20px 40px',
    position: 'absolute',
    boxShadow: '0px 10px 40px 0px rgba(0, 0, 0, 0.15)',
    zIndex: 1,
    top: '150%',
    right: '-20%',
    opacity: 0,
    transition: 'opacity 300ms ease-in-out',
    whiteSpace: 'nowrap',
    fontFamily: 'Roboto, sans-serif',
  },
})

const Tooltip = ({ children, ...props }) => {
  const { classes, className, text, ...extra } = props

  return (
    <div className={classNames(className, classes.tooltip)} {...extra}>
      {children}

      <span className={classes.tooltipText}>{text}</span>
    </div>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
}

export default withStyles(styles)(Tooltip)
