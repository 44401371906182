import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { animated } from '@react-spring/web'
import { ReactPortal } from '~src/new/shared/ui/ReactPortal/ReactPortal'
import cls from './modal.module.scss'
import PopupBarModule from '~components/molecules/PopupBarModule/PopupBarModule'
import Text from '~src/new/shared/ui/Text/Text'
import Icon from '@material-ui/core/Icon'
import { useModalAnimation } from '~src/new/shared/ui/Modal/useModalAnimation'
import { useWidth } from '~src/new/app/providers/WidthProvider'

export const ModalFront = props => {
  const {
    className,
    classNameBody,
    classNameOverlay,
    popupBarStyle,
    children,
    isOpen,
    onClose,
    withBackButton,
    onBackClick,
    contentStyle,
    headerTitle,
    title,
    parent,
    footerElement,
    noPortal,
    hideCross,
    isPictureShow,
    disableAnimation,
    containerProps,
  } = props

  const { isMobile } = useWidth()

  const timerRef = useRef(null)

  const closeHandler = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  const onContentClick = e => {
    e.stopPropagation()
  }

  const onKeyDown = useCallback(
    e => {
      if (e.key === 'Escape') {
        closeHandler()
      }
    },
    [closeHandler],
  )

  useEffect(() => {
    if (!isOpen) return
    const overflow = document.body.style.overflow
    const overscrollBehaviorBody = document.body.style.overscrollBehavior
    const overscrollBehaviorHtml = document.documentElement.style.overscrollBehavior

    window.addEventListener('keydown', onKeyDown)
    document.body.style.overflow = 'hidden'
    document.body.style.overscrollBehavior = 'none'
    document.documentElement.style.overscrollBehavior = 'none'

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
      window.removeEventListener('keydown', onKeyDown)
      document.body.style.overflow = overflow
      document.body.style.overscrollBehavior = overscrollBehaviorBody
      document.documentElement.style.overscrollBehavior = overscrollBehaviorHtml
    }
  }, [onKeyDown, isOpen])

  const isEnableAnimation = isMobile && disableAnimation !== true

  const {
    handleSwipe,
    isRenderChildren: isRenderChildrenByAnimation,
    style: animateStyle,
  } = useModalAnimation(isOpen, onClose, !isEnableAnimation)

  const isRenderChildren = isEnableAnimation ? isRenderChildrenByAnimation : isOpen

  const mods = {
    [cls.opened]: isOpen,
  }

  const withFooter = Boolean(footerElement)

  const ReactPortalNode = noPortal ? React.Fragment : ReactPortal

  return (
    <ReactPortalNode element={parent}>
      <div className={classNames(cls.modal, mods)} {...(containerProps || {})}>
        <div className={classNameOverlay || cls.overlay} onClick={closeHandler}>
          <animated.div
            className={classNames(cls.content, className, withFooter && cls.withFooter)}
            style={{
              ...(contentStyle || {}),
              ...(isEnableAnimation ? animateStyle || {} : {}),
            }}
            onClick={onContentClick}
            {...(isEnableAnimation ? handleSwipe || {} : {})}
          >
            {!isPictureShow && (
              <PopupBarModule
                className={cls.header}
                style={popupBarStyle}
                title={headerTitle}
                onClose={onClose}
                isFakePopup={false}
                noPadding={true}
                withCross={!hideCross}
                withBackButton={withBackButton}
                backButtonClassName={cls.backButton}
                onBackClick={onBackClick}
                id="popup-header"
              />
            )}

            {/*DEPRECATED*/}
            {title && <Text className={cls.title}>{title}</Text>}
            {/*---------*/}
            {isOpen ? (
              <>
                <div className={classNames(cls.body, classNameBody)}>
                  {isRenderChildren ? children : null}
                </div>
                {footerElement ? (
                  <div className={cls.footer}>{isRenderChildren ? footerElement : null}</div>
                ) : null}
              </>
            ) : null}
          </animated.div>
        </div>
        {isPictureShow && (
          <Icon className={cls.closeIcon} onClick={onClose}>
            close
          </Icon>
        )}
      </div>
    </ReactPortalNode>
  )
}

ModalFront.propTypes = {
  className: PropTypes.string,
  classNameBody: PropTypes.string,
  popupBarStyle: PropTypes.object,
  parent: PropTypes.node,
  noPortal: PropTypes.bool,
  hideCross: PropTypes.bool,

  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  withBackButton: PropTypes.bool,
  onBackClick: PropTypes.func,

  contentStyle: PropTypes.object,

  headerTitle: PropTypes.string,

  footerElement: PropTypes.node,

  disableAnimation: PropTypes.bool,
}
